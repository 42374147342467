.connection-status{
    position: fixed;
    top: 1rem;
    left: 50%;
    margin-left: -50px;
    width: 100px;
    z-index: 4;
    background: $danger;
    text-align: center;
    color: ligthen($danger, .5);
    color: $white;
    font-size: .8rem;
    opacity: 1;
    transition: opacity .5s linear;
    

    &.online{
        background: $success;
        opacity: 0;
        transition: opacity 2s linear;
    }

    &.connecting{
        background: $warning;
    }
}
