.app-header {
  height: 3rem;
  border: none !important;
  padding: 0;
  margin: 0;
  border-top: 0.5rem solid #457e90 !important;
  color: $white !important;
  background: #457e90 !important;
  z-index: 101!important;
  

  .navbar-brand {
    background: #457e90;
    height: 2.5rem;
    padding: 0rem 1rem 0.5rem;
    margin-right: 4rem;
    width: auto;
    position: relative;
    &:after {
      content: "";
      border: 1.25rem solid red;
      border-color: #457e90 transparent transparent #457e90;
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      top: 0;
      left: 100%;
      margin-left: -1px;
    }
  }

  .right-content {
    background: #457e90;
    height: 2.5rem;
   padding: 0rem 1rem 0.5rem 0rem;
    width: auto;
    position: relative;
    &:after {
      content: "";
      border: 1.25rem solid red;
      border-color: #457e90 #457e90 transparent transparent;
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      top: 0;
      left: -2.5rem;
    }

    .nav-link{
        padding-top: 0.25rem;
        padding-bottom: 0.75rem;
    }

    .dropdown-menu {
      margin: 0.5rem 0 0;
      position: absolute;
      right: -1rem;
      margin-right: -1px;
      left: auto;
      border-radius: 0;
    }
  }

  @media screen and (min-width: $mobile-break) {
    background: none !important;
    height: 0;
    .navbar-brand {
      width: 350px;
      top: 1.2rem;
    }

    .right-content {
      /*padding-left: 6rem;*/
      top: 1.2rem;
      .dropdown-menu {
        margin-top: 6px;
      }
    }
  }
}
