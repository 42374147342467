.collapsible {
  border: 1px solid $secondary;
  .header {
    background: $secondary;
    padding: 0.5em 1em;
    cursor: pointer;
    position: relative;

    &:before {
      content: "\f415";
      font-family: "Material Design Icons";
      display: block;
      position: absolute;
      width: 1em;
      height: 1em;
      font-weight: bold;
      right: 0.5em;
      top: 0.4em;
      line-height: 1em;
      font-size: 1.5em;
    }
  }

  .content {
    height: 0;
    overflow: hidden;
  }

  &.expanded {
    .content {
      height: auto;
      margin: 1em;
      overflow: visible;
      margin-bottom: 2em;
    }
    .header {
      &:before {
        content: "\f374";
      }
    }
  }
}
