.color-field {
  position: relative;
  overflow: visible;
  .current {
    display: inline-block;
    border: 1px solid $border-color;
    height: 2.5em;
    line-height: 2.5em;
    padding: 0 1em;
    width: 100%;
    cursor: pointer;
    position: relative;
    padding-right: 2.8em;
    &:after {
      content: "\f140";
      font-family: "Material Design Icons";
      width: 1.8rem;
      text-align: center;
      font-size: 1.8em;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      color: $secondary;
      border-left: 1px solid $border-color;
      background: white;
    }
    &:hover {
      &:after {
        color: darken($secondary, 20%);
      }
    }
    &.active {
      &:after {
        color: darken($secondary, 20%);
      }
    }
  }

  .popup {
    position: absolute;
  }
}
