.main-menu {
  width: 100%;
  z-index: 100;
  height: 100%;
  position: fixed;
  display: block;
  visibility: hidden;
  max-width: 300px;
  right: 0;
  

  .content {
    transform: translateX(100%);
    transition: transform 0.3s linear;
    position: fixed;
    top: 0;
    bottom: 0;
    background: $white;
    width: 100%;
    height: 100%;
    right: 0;
    max-width: 300px;
    box-shadow: -1px 0px 1px 0px rgba(0, 0, 0, 0.1);
    border-left: 1px solid $secondary;
    display: flex;
    flex-direction: column;
    .header{
        padding: 1em 1em 0.18em;        
        margin-bottom: 1em;
        flex-grow: 0;
    }
    .menu{
        flex-grow: 1;
        overflow: auto;
        padding-bottom: 1em;
    }
  }

  .overlay {
    position: fixed;
    display: none;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba($dark-bg, 0.5);
    width: 200vw;
  }

  &.active {
    visibility: visible;
    .content {
      transform: translateX(0);
      transition: transform 0.3s linear;
    }
    .overlay {
      display: block;
      opacity: 1;
    }
  }

  @media screen and (min-width: $mobile-break) {
    .overlay {
        //display: none!important;
        background: transparent;
      }
  }
}
