.page-error {
  color: #fff;
  background: $dark-bg;
  .title {
      font-size: 10rem;
      line-height: 1em;
      text-align: center;
      margin-bottom: 1rem;
      padding-bottom: 0;
  }
  .sub-title{
      font-size: 3rem;
      text-align: center;
      margin-bottom: 2rem;
  }
}
