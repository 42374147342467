.device-status {
  font-size: 1.3em;
  position: relative;
  top: 1px;
  display: block;
  float: right;
  color: $secondary;
  margin-left: 0.5em;
  transition: color 0.5s linear;
  width: 1em;

  &.online-status {
    &.online {
      color: $online;
    }
    &.offline {
      color: $offline;
    }
    &.unknown {
      color: $unknown;
    }
  }

  &.ignition-status {
    &.on {
      color: $online;
    }
    &.off {
      color: $offline;
    }
    &.unknown {
      color: $unknown;
    }
  }

  &.motion-status {
    &.moving {
      color: $online;
    }
    &.stopped {
      color: $offline;
    }
    &.unknown {
      color: $unknown;
    }
  }
  &.speed-status {
    &.moving {
      color: $online;
    }
    &.stopped {
      color: $offline;
    }
    &.unknown {
      color: $unknown;
    }
  }
  &.odometer-status {
    color:$black;
    &.moving {
      color: $online;
    }
    &.stopped {
      color: $offline;
    }
    &.unknown {
      color: $unknown;
    }
  }
}
