.report-page {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  display: none;

  .map {
    flex-grow: 1;
    > div {
      min-height: 100%;
      height: 100%;
    }
    .map-wrapper .leaflet-container {
      z-index: 0;
    }
  }

  .page-component {
    .header {
      z-index: 2;
    }
    .body {
      position: relative;
      padding: 0;
      background: $white;
      min-width: 100%;
      min-height: calc(100% - 3.2rem);
      display: flex;
      flex-direction: column;
      max-height: 100%;
      justify-content: center;



      .control {
        font-size: 1.5em;
        position: absolute;
        height: 1.5em;
        width: 3em;
        top: -1.5em;
        left: 20%;
        background: $white;
        border-radius: 0 0 5px 0;
        line-height: 1.5em;
        text-align: center;
        border: 1px solid $border-color;
        border-width: 1px 1px 0 1px;
        opacity: 1;
        cursor: pointer;
        transition: all 0.2s ease-in;

        border-radius: 5px 5px 0 0;

        .button:before {
          content: "\f140";
          opacity: 0.8;
        }

        &:hover {
          /*width: 1.5em;
          right: -1.5em;*/
          .button:after {
            opacity: 1;
          }
        }
      }


      .data {
        height: 30%;
        overflow: visible;
        position: relative;
        z-index: 999;
        transition: height .2s linear;

        &.toggled{
          height: 0;
          .react-bootstrap-table-pagination{
            display: none;
          }
          .control {
            .button:before {
              content: "\f143";
              opacity: 0.8;
            }
          }
        }
      }

      .data-wrapper{
        height: 100%;
        overflow: auto;
        position: relative;
      }

      .table-wrapper {
        position: relative;
        height: 100%;
        min-width: 1500px;
        overflow: hidden;

        
      }

      /*&:before{
        position: fixed;
        content: '';
        width: 50px;
        height: 50px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin-top: -25px;
        margin-left: -25px;
        background: red;
        display: block;
      }*/
    }
  }

  @media screen and (min-width: $mobile-break) {
    .page-component {
      .header {
        background: $secondary;
        color: $black;
        .close {
          color: $black;
        }
      }
      .body{
        .control{
          left: unset;
          right: 20%;
        }
      }
    }
  }
}

.show-report .report-page {
  display: block;
}
