$sizes: ();

$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
$mdi-font-path: "~@mdi/font/fonts";
$mobile-break: 992px;

$dark-bg: rgba(5, 23, 37, 1);
$secondary: rgba(237, 237, 237, 1);
$primary: rgba(69, 126, 144, 1);

$success: rgba(0, 172, 66, 1);
$info: rgba(69, 126, 144, 1);
$warning: rgba(248, 148, 6, 1);
$danger: rgba(189, 54, 47, 1);

/*

$online: rgba(0, 172, 66, 1);
$offline: rgba(212, 48, 67, 1);
$unknown: rgba(250, 190, 77, 1);
*/
//status color
$online: $success;
$offline: $danger;
$unknown: $warning;

//swal
$swal2-border-radius: 0!important;
$swal2-font: "Roboto"!important;
$swal2-font-size: 0.8rem!important;


$swal2-button-focus-outline: transparent!important;

// CONFIRM BUTTON
$swal2-confirm-button-border: 0 !important;
$swal2-confirm-button-border-radius: 0!important;
$swal2-confirm-button-background-color: $primary;

// CANCEL BUTTON
$swal2-cancel-button-border: 0!important;
$swal2-cancel-button-border-radius: 0!important;
$swal2-cancel-button-background-color: $secondary;
